import { createFileRoute, useNavigate } from "@tanstack/react-router";
import { useState } from "react";
import toast from "react-hot-toast";
import VisibilityOff from "~icons/material-symbols/visibility-off-outline";
import Visibility from "~icons/material-symbols/visibility-outline";
import { css } from "../../../styled-system/css";
import { redirectIfAuthenticated } from "../../libs/auth";
import { createAccountClient } from "../../libs/client";

export const Route = createFileRoute("/login/company")({
  beforeLoad: async () => {
    redirectIfAuthenticated();
  },
  component: RouteComponent,
});

function RouteComponent() {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    loginId: "",
    password: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);

    const client = createAccountClient();
    try {
      const res = await client.login({
        slug: formData.loginId,
        password: formData.password,
      });

      if (res.token) {
        localStorage.setItem("token", res.token);
        navigate({ to: "/company/dashboard" });
      }
    } catch (error) {
      console.error(error);
      toast.error("ログインに失敗しました。");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div
      className={css({
        minH: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        bg: "background.primary",
        px: "4",
      })}
    >
      <div
        className={css({
          w: "400px",
          p: "8",
          bg: "white",
          borderRadius: "lg",
          boxShadow: "md",
        })}
      >
        <h1
          className={css({
            fontSize: "2xl",
            fontWeight: "bold",
            textAlign: "center",
            mb: "6",
          })}
        >
          事業者コンソール
        </h1>
        <form onSubmit={handleSubmit} autoComplete="on">
          <div
            className={css({
              mb: "4",
            })}
          >
            <label
              htmlFor="loginId"
              className={css({
                display: "block",
                mb: "2",
                fontSize: "sm",
              })}
            >
              ログインID
            </label>
            <input
              type="text"
              id="loginId"
              required
              autoComplete="username"
              value={formData.loginId}
              onChange={(e) =>
                setFormData({ ...formData, loginId: e.target.value })
              }
              className={css({
                w: "full",
                p: "2",
                border: "1px solid",
                borderColor: "border.primary",
                borderRadius: "md",
                _focus: {
                  outline: "none",
                  borderColor: "surface.accentPrimary",
                },
              })}
            />
          </div>
          <div
            className={css({
              mb: "6",
              position: "relative",
            })}
          >
            <label
              htmlFor="password"
              className={css({
                display: "block",
                mb: "2",
                fontSize: "sm",
              })}
            >
              パスワード
            </label>
            <div className={css({ position: "relative" })}>
              <input
                type={showPassword ? "text" : "password"}
                id="password"
                required
                autoComplete="current-password"
                value={formData.password}
                onChange={(e) =>
                  setFormData({ ...formData, password: e.target.value })
                }
                className={css({
                  w: "full",
                  p: "2",
                  pr: "10",
                  border: "1px solid",
                  borderColor: "border.primary",
                  borderRadius: "md",
                  _focus: {
                    outline: "none",
                    borderColor: "surface.accentPrimary",
                  },
                })}
              />
              <button
                type="button"
                onClick={() => setShowPassword(!showPassword)}
                className={css({
                  position: "absolute",
                  right: "2",
                  top: "50%",
                  transform: "translateY(-50%)",
                  cursor: "pointer",
                  bg: "transparent",
                  border: "none",
                  p: "1",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                })}
              >
                {showPassword ? (
                  <VisibilityOff
                    className={css({ w: "5", h: "5", color: "gray.500" })}
                  />
                ) : (
                  <Visibility
                    className={css({ w: "5", h: "5", color: "gray.500" })}
                  />
                )}
              </button>
            </div>
          </div>
          <button
            type="submit"
            disabled={isLoading}
            className={css({
              w: "full",
              bg: isLoading ? "gray.400" : "surface.accentPrimary",
              color: "white",
              py: "2",
              px: "4",
              borderRadius: "md",
              fontWeight: "bold",
              cursor: isLoading ? "not-allowed" : "pointer",
              _hover: {
                bg: isLoading ? "gray.400" : "surface.accentPrimaryHover",
              },
            })}
          >
            {isLoading ? "ログイン中..." : "ログイン"}
          </button>
        </form>
      </div>
    </div>
  );
}
