import { createFileRoute } from "@tanstack/react-router";
import { css } from "../../../styled-system/css";

export const Route = createFileRoute("/company/dashboard")({
  loader: async ({ context: { company } }) => {
    return { company };
  },
  component: RouteComponent,
});

function RouteComponent() {
  const { company } = Route.useLoaderData();

  return (
    <div
      className={css({
        p: { base: "20px", md: "40px" },
        h: "100%",
        maxWidth: "100%",
        overflowX: "hidden",
      })}
    >
      <h1
        className={css({
          fontSize: "18px",
          fontWeight: "bold",
          mb: "20px",
        })}
      >
        登録情報
      </h1>
      <div
        className={css({
          display: "flex",
          gap: { base: "16px", md: "20px" },
          flexWrap: "wrap",
          wordBreak: "break-all",
        })}
      >
        <section
          className={css({
            bg: "white",
            p: { base: "16px", md: "20px" },
            borderRadius: "10px",
            width: { base: "100%", md: "calc(50% - 10px)" },
          })}
        >
          <h3
            className={css({
              fontSize: "16px",
              fontWeight: "bold",
              mb: "10px",
            })}
          >
            基本情報
          </h3>
          <ul
            className={css({
              display: "flex",
              flexDirection: "column",
              gap: "12px",
            })}
          >
            <li
              className={css({
                display: "flex",
                justifyContent: "space-between",
                borderBottom: "1px solid",
                borderColor: "border.secondary",
                pb: "8px",
              })}
            >
              <span
                className={css({
                  color: "text.secondary",
                  whiteSpace: "nowrap",
                  minWidth: "120px",
                })}
              >
                事業者名
              </span>
              <span>{company.name}</span>
            </li>
            <li
              className={css({
                display: "flex",
                justifyContent: "space-between",
                borderBottom: "1px solid",
                borderColor: "border.secondary",
                pb: "8px",
              })}
            >
              <span
                className={css({
                  color: "text.secondary",
                  whiteSpace: "nowrap",
                  minWidth: "120px",
                })}
              >
                登録代表者名
              </span>
              <span>{company.ownerName}</span>
            </li>
            <li
              className={css({
                display: "flex",
                justifyContent: "space-between",
                borderBottom: "1px solid",
                borderColor: "border.secondary",
                pb: "8px",
              })}
            >
              <span
                className={css({
                  color: "text.secondary",
                  whiteSpace: "nowrap",
                  minWidth: "120px",
                })}
              >
                登録住所
              </span>
              <span>{company.address}</span>
            </li>
            <li
              className={css({
                display: "flex",
                justifyContent: "space-between",
                pb: "8px",
              })}
            >
              <span
                className={css({
                  color: "text.secondary",
                  whiteSpace: "nowrap",
                  minWidth: "120px",
                })}
              >
                電話番号
              </span>
              <span>{company.contact}</span>
            </li>
          </ul>
        </section>
        <section
          className={css({
            bg: "white",
            p: { base: "16px", md: "20px" },
            borderRadius: "10px",
            width: { base: "100%", md: "calc(50% - 10px)" },
          })}
        >
          <h3
            className={css({
              fontSize: "16px",
              fontWeight: "bold",
              mb: "10px",
            })}
          >
            登録銀行口座
          </h3>
          <ul
            className={css({
              display: "flex",
              flexDirection: "column",
              gap: "12px",
            })}
          >
            <li
              className={css({
                display: "flex",
                justifyContent: "space-between",
                borderBottom: "1px solid",
                borderColor: "border.secondary",
                pb: "8px",
              })}
            >
              <span
                className={css({
                  color: "text.secondary",
                  minWidth: "100px",
                })}
              >
                銀行名
              </span>
              <span>{company.bankName}</span>
            </li>
            <li
              className={css({
                display: "flex",
                justifyContent: "space-between",
                borderBottom: "1px solid",
                borderColor: "border.secondary",
                pb: "8px",
              })}
            >
              <span
                className={css({
                  color: "text.secondary",
                  minWidth: "100px",
                })}
              >
                支店名
              </span>
              <span>{company.bankBranchName}</span>
            </li>
            <li
              className={css({
                display: "flex",
                justifyContent: "space-between",
                borderBottom: "1px solid",
                borderColor: "border.secondary",
                pb: "8px",
              })}
            >
              <span
                className={css({
                  color: "text.secondary",
                  minWidth: "100px",
                })}
              >
                口座種別
              </span>
              <span>{company.bankAccountType}</span>
            </li>
            <li
              className={css({
                display: "flex",
                justifyContent: "space-between",
                borderBottom: "1px solid",
                borderColor: "border.secondary",
                pb: "8px",
              })}
            >
              <span
                className={css({
                  color: "text.secondary",
                  minWidth: "100px",
                })}
              >
                口座番号
              </span>
              <span>{company.bankAccountNumber}</span>
            </li>
            <li
              className={css({
                display: "flex",
                justifyContent: "space-between",
                pb: "8px",
              })}
            >
              <span
                className={css({
                  color: "text.secondary",
                  minWidth: "100px",
                })}
              >
                口座名義
              </span>
              <span>{company.bankAccountHolderName}</span>
            </li>
          </ul>
        </section>
      </div>
    </div>
  );
}
