import { Link, createFileRoute, redirect } from "@tanstack/react-router";
import {
  type ChangeEventHandler,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import toast from "react-hot-toast";
import OpenInNew from "~icons/material-symbols/open-in-new";
import { css } from "../../../../../styled-system/css";
import { type ResizedImage, resizeImage } from "../../../../libs/image";

export const Route = createFileRoute("/company/stores/$storeSlug/")({
  loader: async ({ context: { stores, client }, params: { storeSlug } }) => {
    const store = stores.find((store) => store.slug === storeSlug);
    if (!store) {
      throw redirect({ to: "/company/stores" });
    }
    return { store, client };
  },
  component: RouteComponent,
});

function RouteComponent() {
  const { store, client } = Route.useLoaderData();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [image, setImage] = useState<ResizedImage | null>(null);
  const [previewUrl, setPreviewUrl] = useState<string | null>(null);
  const [timestamp, setTimestamp] = useState<number>(Date.now());

  useEffect(() => {
    return () => {
      if (previewUrl) {
        URL.revokeObjectURL(previewUrl);
      }
    };
  }, [previewUrl]);

  const onFileChange = useCallback<ChangeEventHandler<HTMLInputElement>>(
    async (event) => {
      const file = event.target.files?.[0];
      if (!file) return;

      // jpg, png以外は利用できない
      if (!["image/jpeg", "image/png"].includes(file.type)) {
        toast.error("JPGまたはPNG形式の画像のみアップロード可能です");
        return;
      }

      // プレビュー用のURLを作成
      if (previewUrl) {
        URL.revokeObjectURL(previewUrl);
      }
      const newPreviewUrl = URL.createObjectURL(file);
      setPreviewUrl(newPreviewUrl);

      const resizedImage = await resizeImage(file);
      if (resizedImage) {
        setImage(resizedImage);
      } else {
        toast.error("画像の処理に失敗しました");
        setPreviewUrl(null);
      }
    },
    [previewUrl],
  );

  const onClickUpdateIcon = useCallback(async () => {
    if (!image) return;

    try {
      await client.updateStoreImage({
        storeId: store.id,
        image,
      });
      setImage(null);
      setPreviewUrl(null);
      setTimestamp(Date.now());
      if (fileInputRef.current) {
        fileInputRef.current.value = "";
      }
      toast.success("店舗アイコンを更新しました");
      location.reload();
    } catch (error) {
      console.error("Error updating store image:", error);
      toast.error("店舗アイコンの更新に失敗しました");
    }
  }, [client, image, store.id]);

  return (
    <div
      className={css({
        p: { base: "20px", md: "40px" },
        pt: { base: "20px ", md: "20px" },
        h: "100%",
        maxWidth: "100%",
        overflowX: "hidden",
      })}
    >
      <div className={css({ mb: "4" })}>
        <Link to="/company/stores">店舗一覧に戻る</Link>
      </div>
      <h1
        className={css({
          fontSize: "18px",
          fontWeight: "bold",
          mb: "20px",
        })}
      >
        登録情報
      </h1>
      <div
        className={css({
          display: "flex",
          gap: { base: "16px", md: "20px" },
          flexWrap: "wrap",
        })}
      >
        <section
          className={css({
            bg: "white",
            p: { base: "16px", md: "20px" },
            borderRadius: "10px",
            width: { base: "100%", md: "calc(50% - 10px)" },
          })}
        >
          <h3
            className={css({
              fontSize: "16px",
              fontWeight: "bold",
              mb: "10px",
            })}
          >
            基本情報
          </h3>
          <ul
            className={css({
              display: "flex",
              flexDirection: "column",
              gap: "12px",
            })}
          >
            <li
              className={css({
                display: "flex",
                justifyContent: "space-between",
                borderBottom: "1px solid",
                borderColor: "border.secondary",
                pb: "8px",
              })}
            >
              <span
                className={css({
                  color: "text.secondary",
                  minWidth: "100px",
                  whiteSpace: "nowrap",
                })}
              >
                店舗名
              </span>
              <span className={css({ wordBreak: "break-all" })}>
                {store.name}
              </span>
            </li>

            <li
              className={css({
                display: "flex",
                justifyContent: "space-between",
                borderBottom: "1px solid",
                borderColor: "border.secondary",
                pb: "8px",
              })}
            >
              <span
                className={css({
                  color: "text.secondary",
                })}
              >
                QRコードID
              </span>
              <span>{store.slug}</span>
            </li>

            <li
              className={css({
                display: "flex",
                justifyContent: "space-between",
                borderBottom: "1px solid",
                borderColor: "border.secondary",
                pb: "8px",
              })}
            >
              <span
                className={css({
                  color: "text.secondary",
                })}
              >
                登録住所
              </span>
              <span>{store.fullAddress}</span>
            </li>
            <li
              className={css({
                display: "flex",
                justifyContent: "space-between",
                pb: "8px",
              })}
            >
              <span
                className={css({
                  color: "text.secondary",
                })}
              >
                位置情報
              </span>
              <div>
                {store.location?.latitude && store.location?.longitude ? (
                  <div
                    className={css({
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      gap: "4px",
                    })}
                  >
                    <div>{`${store.location.latitude}, ${store.location.longitude}`}</div>
                    <a
                      href={`https://www.google.com/maps/search/?api=1&query=${store.location?.latitude},${store.location?.longitude}`}
                      target="_blank"
                      rel="noreferrer"
                      className={css({
                        display: "flex",
                        alignItems: "center",
                        gap: "4px",
                        color: "text.accentPrimary",
                      })}
                    >
                      Google Maps
                      <OpenInNew className={css({})} />
                    </a>
                  </div>
                ) : (
                  <div>登録なし</div>
                )}
              </div>
            </li>
          </ul>
        </section>

        <section
          className={css({
            bg: "white",
            p: { base: "16px", md: "20px" },
            borderRadius: "10px",
            width: { base: "100%", md: "calc(50% - 10px)" },
          })}
        >
          <h3
            className={css({
              fontSize: "16px",
              fontWeight: "bold",
              mb: "10px",
            })}
          >
            店舗アイコン
          </h3>

          <div
            className={css({
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            })}
          >
            <img
              src={previewUrl || `${store.iconUrl}?timestamp=${timestamp}`}
              alt="店舗アイコン"
              className={css({
                maxW: "150px",
                maxH: "150px",
                w: "150px",
                h: "150px",
                objectFit: "cover",
                objectPosition: "center",
              })}
            />
          </div>

          <div
            className={css({
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              gap: "10px",
            })}
          >
            <input
              type="file"
              accept="image/png, image/jpeg"
              ref={fileInputRef}
              className={css({
                display: "block",
                mt: "10px",
                mx: "auto",
                fontSize: "14px",
                _file: {
                  bg: "background.background",
                  px: "10px",
                  py: "4px",
                  borderRadius: "5px",
                  mr: "10px",
                },
              })}
              onChange={onFileChange}
            />
            <button
              type="button"
              disabled={!image}
              onClick={onClickUpdateIcon}
              className={css({
                bg: "surface.accentPrimary",
                color: "white",
                borderRadius: "5px",
                px: "40px",
                fontSize: "14px",
                py: "5px",
                display: "block",
                mx: "auto",
                _disabled: {
                  bg: "surface.disable",
                },
              })}
            >
              店舗アイコンに設定する
            </button>
          </div>
        </section>
      </div>
    </div>
  );
}
