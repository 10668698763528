import { Dialog, DialogPanel, DialogTitle } from "@headlessui/react";
import { Link, createFileRoute, useNavigate } from "@tanstack/react-router";
import { useState } from "react";
import type { Store } from "schema/gen/es/chiikipoint/model/v2/model_pb";
import { css } from "../../../../styled-system/css";

export const Route = createFileRoute("/company/stores/")({
  loader: async ({ context: { stores } }) => {
    const timestamp = Date.now();
    return { stores, timestamp };
  },
  component: RouteComponent,
});

function RouteComponent() {
  const { stores, timestamp } = Route.useLoaderData();
  const [selectedStore, setSelectedStore] = useState<Store | null>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [qrId, setQrId] = useState("");
  const [qrIdError, setQrIdError] = useState("");
  const navigate = useNavigate();

  const validateQrId = (value: string) => {
    if (value.length > 18) {
      setQrIdError("レジ番号は18文字以内で入力してください");
      return false;
    }
    if (/[\s#%?&=+<>{}|\^~[\]`"\\/:@;,!'$()\]]/.test(value)) {
      setQrIdError("レジ番号に使用できない文字が含まれています");
      return false;
    }
    setQrIdError("");
    return true;
  };

  const handleQrIdChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setQrId(value);
    validateQrId(value);
  };

  const handleClickDownload = () => {
    if (!selectedStore) return;
    if (!validateQrId(qrId)) return;
    navigate({
      to: `/company/stores/${selectedStore.slug}/qrcode`,
      search: qrId ? { qrId } : undefined,
    });
  };

  return (
    <div
      className={css({
        px: { base: "20px", md: "40px" },
        py: "24px",
        maxWidth: { base: "100vw", md: "100%" },
      })}
    >
      <h1
        className={css({
          fontSize: "18px",
          fontWeight: "bold",
          mb: "20px",
        })}
      >
        店舗一覧
      </h1>

      <div
        className={css({
          bg: "white",
          borderRadius: "10px",
          overflow: "hidden",
          border: "1px solid",
          borderColor: "border.secondary",
        })}
      >
        <div
          className={css({
            overflowX: {
              base: "auto",
              md: "hidden",
            },
          })}
        >
          <table
            className={css({
              width: "100%",
              minWidth: "800px",
              borderCollapse: "collapse",
            })}
          >
            <thead>
              <tr
                className={css({
                  borderBottom: "1px solid",
                  borderColor: "border.secondary",
                  bg: "white",
                })}
              >
                <th
                  className={css({
                    minWidth: "60px",
                  })}
                />
                <th
                  className={css({
                    py: "12px",
                    px: "16px",
                    textAlign: "left",
                    fontWeight: "normal",
                    color: "text.secondary",
                    maxWidth: "200px",
                  })}
                >
                  店舗名
                </th>
                <th
                  className={css({
                    py: "12px",
                    px: "16px",
                    textAlign: "left",
                    fontWeight: "normal",
                    color: "text.secondary",
                    whiteSpace: "nowrap",
                  })}
                >
                  住所
                </th>
                <th
                  className={css({
                    py: "12px",
                    px: "16px",
                    textAlign: "left",
                    fontWeight: "normal",
                    color: "text.secondary",
                    whiteSpace: "nowrap",
                  })}
                >
                  カテゴリ
                </th>
                <th />
              </tr>
            </thead>
            <tbody>
              {stores.map((store) => (
                <tr
                  key={store.id}
                  className={css({
                    borderBottom: "1px solid",
                    borderColor: "border.secondary",
                    _last: {
                      borderBottom: "none",
                    },
                  })}
                >
                  <td>
                    <div
                      className={css({
                        py: "4px",
                        pl: "16px",
                      })}
                    >
                      <img
                        src={`${store.iconUrl}?timestamp=${timestamp}`}
                        alt={store.name}
                        className={css({
                          w: "40px",
                          h: "40px",
                          borderRadius: "50%",
                          objectFit: "cover",
                          objectPosition: "center",
                        })}
                      />
                    </div>
                  </td>
                  <td
                    className={css({
                      py: "12px",
                      px: "16px",
                      wordBreak: "break-all",
                      maxWidth: "320px",
                    })}
                  >
                    {store.name}
                  </td>
                  <td
                    className={css({
                      py: "12px",
                      px: "16px",
                    })}
                  >
                    {store.fullAddress}
                  </td>
                  <td
                    className={css({
                      py: "12px",
                      px: "16px",
                      whiteSpace: "nowrap",
                    })}
                  >
                    {store.category}
                  </td>
                  <td
                    className={css({
                      py: "12px",
                      px: "16px",
                      textAlign: "center",
                    })}
                  >
                    <div className={css({ display: "flex", gap: "12px" })}>
                      <button
                        type="button"
                        onClick={() => {
                          setSelectedStore(store);
                          setIsOpen(true);
                        }}
                        className={css({
                          color: "surface.accentPrimary",
                          border: "1px solid",
                          borderColor: "surface.accentPrimary",
                          borderRadius: "4px",
                          px: "4px",
                          py: "4px",
                          fontSize: "14px",
                          cursor: "pointer",
                          whiteSpace: "nowrap",
                          minWidth: "90px",
                        })}
                      >
                        QRコード
                      </button>

                      <Link
                        to={`/company/stores/${store.slug}`}
                        className={css({
                          color: "surface.accentPrimary",
                          border: "1px solid",
                          borderColor: "surface.accentPrimary",
                          borderRadius: "4px",
                          px: "4px",
                          py: "4px",
                          fontSize: "14px",
                          cursor: "pointer",
                          minWidth: "90px",
                        })}
                      >
                        詳細
                      </Link>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      <Dialog
        open={isOpen}
        onClose={() => setIsOpen(false)}
        className={css({
          position: "relative",
          zIndex: "50",
        })}
      >
        <div
          className={css({
            position: "fixed",
            inset: "0",
            bg: "black/30",
            top: "0",
            left: "0",
            right: "0",
            bottom: "0",
          })}
          aria-hidden="true"
        />
        <div
          className={css({
            position: "fixed",
            inset: "0",
            display: "flex",
            width: "100vw",
            alignItems: "center",
            justifyContent: "center",
            p: "4",
          })}
        >
          <DialogPanel
            className={css({
              bg: "white",
              p: "24px",
              borderRadius: "8px",
              maxW: "400px",
              w: "full",
            })}
          >
            <DialogTitle
              className={css({
                fontSize: "18px",
                fontWeight: "bold",
                mb: "16px",
              })}
            >
              QRコードタグ設定
            </DialogTitle>

            <div className={css({ mb: "16px" })}>
              <div>
                <input
                  id="qr-tag"
                  type="text"
                  value={qrId}
                  placeholder="レジ番号１"
                  onChange={handleQrIdChange}
                  className={css({
                    w: "full",
                    p: "8px",
                    border: "1px solid",
                    borderColor: qrIdError ? "red.500" : "border.secondary",
                    borderRadius: "4px",
                  })}
                />
                {qrIdError && (
                  <div
                    className={css({
                      color: "red.500",
                      fontSize: "14px",
                      mt: "4px",
                    })}
                  >
                    {qrIdError}
                  </div>
                )}
                <div
                  className={css({
                    bg: "surface.accentWarnLight",
                    px: "8px",
                    py: "4px",
                    borderRadius: "4px",
                    border: "1px solid",
                    borderColor: "border.secondary",
                    mt: "8px",
                  })}
                >
                  QRコードタグは、複数のQRコードを利用する際に取引履歴を識別するために利用します。必要がなければ、空欄のままで問題ありません。
                </div>
              </div>
            </div>

            <div
              className={css({
                display: "flex",
                gap: "8px",
                justifyContent: "flex-end",
              })}
            >
              <button
                type="button"
                onClick={() => setIsOpen(false)}
                className={css({
                  px: "16px",
                  py: "8px",
                  border: "1px solid",
                  borderColor: "border.secondary",
                  borderRadius: "4px",
                })}
              >
                キャンセル
              </button>
              <button
                type="button"
                onClick={() => {
                  handleClickDownload();
                }}
                className={css({
                  px: "16px",
                  py: "8px",
                  bg: "surface.accentPrimary",
                  color: "white",
                  borderRadius: "4px",
                })}
              >
                印刷画面を表示する
              </button>
            </div>
          </DialogPanel>
        </div>
      </Dialog>
    </div>
  );
}
