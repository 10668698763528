import { createFileRoute } from "@tanstack/react-router";
import OpenInNew from "~icons/material-symbols/open-in-new";
import { css } from "../../../styled-system/css";
import { MANUAL_URL } from "../../config";

export const Route = createFileRoute("/company/contact")({
  component: RouteComponent,
});

function RouteComponent() {
  return (
    <div
      className={css({
        h: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        px: { base: "20px", md: "40px" },
      })}
    >
      <section
        className={css({
          bg: "white",
          display: "flex",
          flexDirection: "column",
          gap: "6",
          p: "8",
          textAlign: "center",
          rounded: "lg",
          shadow: "sm",
        })}
      >
        <h2
          className={css({
            fontSize: "xl",
            fontWeight: "bold",
          })}
        >
          お問い合わせ
        </h2>
        <div
          className={css({
            display: "flex",
            flexDirection: "column",
            gap: "6",
          })}
        >
          <div
            className={css({
              display: "flex",
              flexDirection: "column",
              gap: "2",
            })}
          >
            <p className={css({ fontWeight: "bold" })}>
              操作の不明点などのご参考情報
            </p>

            <a
              href={MANUAL_URL}
              target="_blank"
              rel="noreferrer"
              className={css({
                bg: "surface.accentPrimary",
                color: "white",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "2",
                px: "4",
                py: "1.5",
                rounded: "lg",
                mx: "auto",
              })}
            >
              <span>操作マニュアル</span>
              <OpenInNew />
            </a>
          </div>

          <div
            className={css({
              display: "flex",
              flexDirection: "column",
              gap: "2",
            })}
          >
            <p className={css({ fontWeight: "bold" })}>
              お電話でのお問い合わせ
            </p>
            <p
              className={css({
                fontSize: "xl",
                fontWeight: "bold",
              })}
            >
              022-797-9582
            </p>
          </div>
        </div>
        <p>受付時間: 祝日と年末年始を除く10時から17時まで</p>
      </section>
    </div>
  );
}
