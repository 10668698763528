export type ResizedImage = {
  image: Uint8Array;
  mimeType: string;
};

export async function resizeImage(
  file: File,
  maxSize = 512,
): Promise<ResizedImage | null> {
  return new Promise((resolve) => {
    const img = new Image();
    img.onload = () => {
      const canvas = document.createElement("canvas");
      let width = img.width;
      let height = img.height;

      // maxSize以内にリサイズ
      if (width > maxSize || height > maxSize) {
        if (width > height) {
          height = Math.round((height * maxSize) / width);
          width = maxSize;
        } else {
          width = Math.round((width * maxSize) / height);
          height = maxSize;
        }
      }

      canvas.width = width;
      canvas.height = height;
      const ctx = canvas.getContext("2d");
      if (!ctx) {
        resolve(null);
        return;
      }

      ctx.drawImage(img, 0, 0, width, height);
      canvas.toBlob(
        (blob) => {
          if (!blob) {
            resolve(null);
            return;
          }
          const reader = new FileReader();
          reader.onload = (e) => {
            const arrayBuffer = e.target?.result as ArrayBuffer;
            resolve({
              image: new Uint8Array(arrayBuffer),
              mimeType: file.type,
            });
          };
          reader.onerror = () => {
            resolve(null);
          };
          reader.readAsArrayBuffer(blob);
        },
        file.type,
        0.9,
      );
    };

    img.onerror = () => {
      resolve(null);
    };

    img.src = URL.createObjectURL(file);
  });
}
