import { redirect } from "@tanstack/react-router";
import { decodeJwt } from "jose";

export function validateToken(token: string | null): boolean {
  if (!token) {
    return false;
  }

  try {
    const payload = decodeJwt(token);
    if (payload.exp && Date.now() >= payload.exp * 1000) {
      return false;
    }
    return true;
  } catch (e) {
    return false;
  }
}

export function requireAuth(): string {
  const token = localStorage.getItem("token");
  if (!validateToken(token)) {
    localStorage.removeItem("token");
    throw redirect({ to: "/login/company" });
  }
  return token as string;
}

export function redirectIfAuthenticated() {
  const token = localStorage.getItem("token");
  if (validateToken(token)) {
    throw redirect({ to: "/company/dashboard" });
  }
}
